.tabs-cont {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5%;
  width: 100%;
  height: 100%;
}

.tab-cont {
  width: 200px;
}
