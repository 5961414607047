/* Color styles */
:root {
  --p-l--bright--blue: rgba(62, 118, 203, 1);
  --p-l--blue: rgba(63, 84, 127, 1);
  --black: rgba(0, 0, 0, 1);
  --gray-6: rgba(42, 42, 42, 1);
  --gray-5: rgba(81, 88, 101, 1);
  --gray-4: rgba(168, 178, 197, 1);
  --gray-3: rgba(216, 220, 229, 1);
  --gray-2: rgba(238, 240, 244, 1);
  --gray-1: rgba(248, 248, 250, 1);
  --white: rgba(255, 255, 255, 1);
  --p-l--orange: rgba(255, 105, 0, 1);
  --p-l--light--orange: rgba(255, 180, 128, 1);
  --green: rgba(146, 205, 40, 1);
  --red: rgba(235, 87, 87, 1);
  --yellow: rgba(255, 195, 5, 1);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.padding-small {
  padding: 5px;
}

.page-holder {
  width: calc(100% - 80px);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  margin-right: 60px;
  margin-left: 40px;
  margin-top: 20px;
}

div.uncompleted {
  background-color: var(--gray-2);
  border-radius: 3px;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.5);
}

div.hide-overflow {
  white-space: nowrap;
  overflow: hidden;
}

.margin-left {
  margin-left: 5px;
}

.flex-row-even {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
}
