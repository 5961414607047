.modal-header {
  background-color: var(--p-l--bright--blue);
  height: 50px;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  margin: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-justify: center;
  display: flex;
  align-items: center;

}