.tile-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 50px;
}

.dash-tile {
  width: 300px;
  height: 150px;
  padding: 10px;
  background-color: '#D8DCE5';
  border-radius: 3px;
  border: 1px solid #d8dce5;
  box-shadow: 0px 2px 4px 0px #0000001f;
}

.tile-organizer {
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 65% 35%;
}

.main-metric-holder {
  grid-column-start: 1;
  grid-column-end: end;
  grid-row-start: 1;
  grid-row-end: 2;
  border-bottom: 1px solid black;
}

.border-right {
  border-right: 1px solid black;
}

.main-metric-organizer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.main-metric-data {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.big-number {
  font-size: '3rem';
  font-weight: bold;
}

.delta-metric-organizer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.delta-metric-data {
  size: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
