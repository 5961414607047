.header-organizer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.section-container {
  width: 100%;
}

.header-content {
  display: grid;
  grid-template-columns: 300px auto;
  width: 100%;
}