.main-header {
  background-color: var(--p-l--blue);
  height: 70px;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 260px 500px auto 130px;
  grid-template-areas: 'logo tabs notifications toolbar';
}

.header-logo-area {
  grid-area: logo;
}

.header-logo {
  margin-left: 40px;
  margin-top: 10px;
}

.tabs-area {
  position: relative;
  grid-area: tabs;
}

.notification-popup-area {
  grid-area: notifications;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-bar-area {
  grid-area: toolbar;
}

.center-vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
